.content-index {
  padding-top: 20px;
  padding-bottom: 20px;

  // type grape / producer
  ////////////////////////
  &__head {
    @include fontSize($fontSizeXXXMedium);
    display: block;
    color: $contentIndexHeadColor;
    border-bottom: $contentIndexBorder;
    text-transform: uppercase;
    font-weight: $fontWeightSemiBold;
    padding: 20px 0 4px;
    margin: 0 0 5px;

    &--empty {
      display: none;
    }
  }

  &__item {
    @include fontSize($fontSizeMedium);
    color: $contentIndexTextColor;
    display: inline-block;
    text-decoration: none;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 5px 0;

    &:not(&--nolink):hover {
      text-decoration: underline;
    }

    @media (min-width: $screen-mobile-landscape) and (max-width: $screen-desktop) {
      width: 47%;

      &:nth-child(odd) {
        margin-right: 2%;
      }
    }

    @media (min-width: $screen-desktop) {
      width: 30%;

      &:not(:nth-child(3n)) {
        margin-right: 1%;
      }
    }
  }

  &__header {
    position: sticky; // stylelint-disable-line plugin/no-unsupported-browser-features
    top: $contentIndexHeadTopM; // header height

    @media (min-width: $screen-tablet-portrait) {
      top: $contentIndexHeadTopD; // header height
    }
  }

  // type origin
  //////////////
  &__country {
    @include fontSize($fontSizeXLarge);

    @include iconFont(arrowDown, after) {
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
      transition: transform .2s;
    }
    position: relative;
    padding: 20px;
    background: $brandPrimaryColor;
    color: $contentIndexCountryColor;
    cursor: pointer;

    &--closed {
      margin-bottom: 3px;

      &::after {
        transform: translateY(-50%) scaleY(-1);
      }

      & + * {
        display: none;
      }
    }
  }

  &__origin-container {
    @include fontSize($fontSizeMedium);
    margin: 0 10px 20px;
    padding-top: 5px;
    border: $borderDefaultColor 1px solid;
    border-top: 0;
    border-bottom: 0;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / .2);
  }

  &__country-link {
    color: $contentIndexTextColor;
    text-decoration: underline;
    display: inline-block;
    padding-bottom: 5px;
  }

  &__origin-link {
    @include iconFont(arrowRightBold) {
      color: $contentIndexArrowColor;
      font-size: 60%;
      margin-right: 5px;
    }
    color: $contentIndexTextColor;
    text-decoration: none;
  }

  &__origin {
    $indent: 20px;
    padding-left: $indent;
    border-bottom: $borderDefaultColor 1px solid;
    line-height: 230%;

    &--country-producer {
      padding-left: $indent * 2;
    }

    &--region-producer {
      padding-left: $indent * 2;
    }
  }
}

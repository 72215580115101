/* stylelint-disable  plugin/no-unsupported-browser-features */

$mcs: 'enzo';

// Colors
$blackColor: #222;
$grayDarkColor: #262626;
$grayMediumColor: #505050;
$grayColor: #979797;
$grayLightColor: #c5c5c5;
$grayLighterColor: #f1f0e8;
$whiteColor: #fff;
$greenColor: #417505;
$greenLightColor: #e3e370;
$greenLighterColor: #ebebc4;
$greenDarkColor: #4a491c;
$goldColor: #c98b25;
$sandGrayColor: #e1dbcb;
$redColor: #b23f2a;
$redDarkColor: #b23f2a;
$petrolColor: #748b93;
$orangeColor: #f6a445;

$brandPrimaryColor: $greenDarkColor;
$brandSecondaryColor: $greenLightColor;
$brandTertiaryColor: $grayLighterColor;
$brandHighlightColor: $redColor;

$paypalcheckout: #fbc438;
$milesAndMoreColor: #071257;
$aboColor: #a48e6b;
$aboBrownLightColor: #fef6e5;
$personalizationColor: #cdb998;

$moodOneColor: #6a0d24; // Diese Variablen bitte nicht mehr benutzen. Niemand weiß wozu diese Farben
$moodTwoColor: #6a0d24; // gut sind und was sich alles ändert, wenn man sie anpasst.
$moodThreeColor: #6a0d24;
$moodFourColor: #6a0d24;

$uspTeaserIconColor: #484824;
$kundenbewertungBackgroundColor: #f3f3c1;
$productDetailedPageCarouselBackgroundColor: #ececdb;

// Basket
$basketGreenColor: #7a9e66;
$basketRedColor: #b00;
$basketPaypalColor: #eee;
$basketPaypalHoverColor: #ccc;

// Body
$bodyBackgroundColor: $grayLighterColor;

// Status
$errorColor: $redColor;
$warningColor: $orangeColor;
$waitingColor: $orangeColor;
$successColor: $greenColor;
$infoColor: $petrolColor;
$availableColor: $greenColor;
$laterAvailableColor: $orangeColor;
$soldOutColor: #d0021b;
$discountColor: $redColor;
$successBasketColor: $greenColor;

// Text
$textLightColor: $whiteColor;
$textLightGrayColor: $grayLightColor;
$textGrayColor: $grayDarkColor;
$textMediumGrayColor: $grayColor;
$textBaseColor: $greenDarkColor;
$textDarkestColor: $greenDarkColor;
$textWarningColor: $warningColor;
$textErrorColor: $errorColor;
$textSuccessColor: $successColor;
$textInfoColor: $infoColor;
$textHoverColor: $brandPrimaryColor;

// Borders
$borderLightColor: $grayLighterColor;
$borderWhiteColor: #fff;
$borderDefaultColor: $greenDarkColor;
$borderDarkColor: #aaa;
$borderBlackColor: #000;
$borderPrimaryColor: $grayMediumColor;
$borderAccountAddress: $grayColor;
$baseBorderRadius: 0;
$baseBorderRadiusSmall: 0;

// Backgrounds
$backgroundWhiteColor: $whiteColor;
$backgroundGreenColor: $successColor;
$backgroundDarkColor: $blackColor;
$backgroundTableColor: $grayLightColor;
$backgroundWarningColor: $warningColor;
$backgroundGrayColor: $grayLighterColor;
$backgroundDarkGrayColor: $grayMediumColor;
$backgroundPrimaryColor: $grayLightColor;

// Badgets
$badgetBackgroundColor: $goldColor;
$badgetTextColor: $whiteColor;

// Remove
$removeColor: $blackColor;
$removeHoverColor: $textErrorColor;

// Fonts
$fontFamily: 'Montserrat', 'Helvetica Neue', -apple-system, system-ui, sans-serif;
$fontPrimaryFamily: 'Montserrat', 'Helvetica Neue', -apple-system, system-ui, sans-serif;
$fontHeadlineFamily: 'Trocchi', 'Times New Roman', Times, serif;
$fontSecondaryFamily: 'Trocchi', 'Times New Roman', Times, serif;

$fontLineHeightRegular: 1.5;

// Font weights
$fontWeightThin: 300;
$fontWeightRegular: 400;
$fontWeightMedium: 500;
$fontWeightSemiBold: 600;
$fontWeightBold: 700;

// Font sizes
$fontSizeXXSmall: 10px;
$fontSizeXSmall: 12px;
$fontSizeSmall: 14px;
$baseFontSize: 16px;
$fontSizeBase: 16px;
$fontSizeFont: 16px;
$fontSizeMedium: 18px;
$fontSizeXMedium: 22px;
$fontSizeXYMedium: 22px;
$fontSizeXXMedium: 24px;
$fontSizeXXXMedium: 26px;
$fontSizeLarge: 28px;
$fontSizeXLarge: 29px;
$fontSizeXXLarge: 32px;
$fontSizeXXXLarge: 40px;
$fontSizeHuge: 68px;

/* stylelint-enable  plugin/no-unsupported-browser-features */

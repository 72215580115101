.order-position {
  $root: &;
  display: flex;
  flex-flow: row wrap;
  position: relative;

  &--unavailable {
    #{$root}__name,
    #{$root}__image {
      opacity: .6;
    }
  }

  &--recurring-order {
    border: 1px solid $orderPositionAboColor;

    #{$root}__delete-btn {
      top: 36px;
    }
  }

  &__info {
    display: flex;
    flex-flow: column;
    flex: 1 1;
  }

  &__image-container {
    padding-right: 8px;
    width: 70px;
    max-width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: $screen-mobile-sm) {
      width: 35%;
    }

    @media (min-width: $screen-mobile-landscape) {
      width: 30%;
    }

    @media (min-width: $screen-tablet-portrait) {
      width: 25%;
    }

    @media (min-width: $screen-tablet-landscape) {
      width: 20%;
    }
  }

  &__image {
    width: auto;
    max-width: 70px;
    position: relative;

    @media (min-width: $screen-mobile-sm) {
      max-width: 100px;
    }

    @media (min-width: $screen-mobile-landscape) {
      max-width: 110px;
    }

    @media (min-width: $screen-tablet-portrait) {
      max-width: 120px;
    }
  }

  &__name {
    color: $grayDarkColor;
    text-decoration: none;
    line-height: 130%;
    margin: 0 24px 4px 0;
    display: block;
    font-weight: $orderPositionNameFontWeight;
  }

  &__bottom {
    display: flex;
    align-items: center;
    font-size: $fontSizeMedium;
  }

  &__price {
    display: flex;
    flex-flow: row wrap;
    gap: 8px;
    align-items: center;
    width: 40%;
    max-width: 180px;
  }

  &__price-current {
    font-weight: $fontWeightMedium;

    &--discount {
      color: $priceDiscountColor;
    }
  }

  &__price-old {
    @include strikethrough($grayColor);
    font-size: .875em;
  }

  &__price-summary {
    font-weight: $fontWeightBold;
    text-align: right;
    white-space: nowrap;

    @media (min-width: $screen-tablet-landscape) {
      width: 33%;
    }
  }

  &__qty {
    flex: 1 1;
    text-align: left;
    padding: 6px 12px 0 6px;
    position: relative;

    @media (min-width: $screen-mobile-large) {
      padding: 0 16px;
    }

    @media (min-width: $screen-tablet-landscape) {
      padding: 0 24px 0 16px;
    }
  }

  &__delete-btn {
    @include iconFont(delete);
    font-size: 10px;
    color: $grayColor;
    position: absolute;
    top: 0;
    right: 0;
    padding: 17px;
    cursor: pointer;
    border: 0 none;
    background: transparent;

    &:hover {
      color: $redColor;
    }
  }

  &__relation-btn {
    text-decoration: underline;
    color: $brandPrimaryColor;
    cursor: pointer;
    font-size: $fontSizeXSmall;
    margin: 8px 0;
    display: inline-block;
  }

  &__reduced-flag {
    position: absolute;
    background: $redColor;
    color: $whiteColor;
    padding: 4px 6px 2px;
    border-radius: 1px;
    font-size: 14px;

    &::before {
      content: '-';
    }

    &::after {
      content: '%';
    }

    #{$root}--recurring-order & {
      margin-top: 24px;
    }
  }

  &__loader {
    position: absolute;
    top: 18px;
    right: 12px;
  }

  &__promotion {
    background: $productOrderPositionPromotionBg;
    color: $productOrderPositionPromotionText;
    font-size: $productOrderPositionPromotionFontSize;
    margin: 4px 4px 0 0;
    padding: 4px 8px;
    text-align: center;
    display: inline-block;
    border-radius: 4px;

    &--miles-and-more {
      background: $milesAndMoreColor;
    }
  }

  &__greeting-text {
    margin: -4px 0 8px;
  }

  &__recurring-order-head {
    width: calc(100% + 16px);
    margin: -16px -8px 8px;
    background: $orderPositionAboColor;
    color: $whiteColor;
    text-align: center;
    font-weight: $fontWeightMedium;
    padding: 8px 0;
    font-size: 16px;

    @media (min-width: $screen-tablet-portrait) {
      width: calc(100% + 24px);
      margin: -16px -16px 16px -8px;
    }
  }

  &__tags {
    margin-bottom: 6px;
  }

  &__recurring-order {
    margin: 8px 0 0;
  }

  &__product-info {
    font-size: .75rem;
    line-height: 1.2;
  }

  .availability-info {
    margin-bottom: 4px;
    flex: 1 1;

    &--label {
      text-transform: none;
    }

    &__label {
      text-align: left;
      margin: 0;

      @media (min-width: $screen-tablet-portrait) {
        margin: 0 0 0 calc(-1 * $availabilityInfoSpaceMinusLeft);
      }
    }
  }

  .counter {
    &--disabled {
      //height: 32px;
      //cursor: default;
    }

    &__input {
      &--disabled {
        //background: transparent;
        //border: none;
      }
    }
  }
}

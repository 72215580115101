.component-placeholder {
  animation: 1s linear forwards infinite placeHolderAnimation;
  background: $componentPlaceholderBackground;
  background: $componentPlaceholderGradient; // stylelint-disable-line
  background-size: 200% 100%;
  position: relative;
}

@keyframes placeHolderAnimation {
  0% {
    background-position: 100% 0;
  }

  100% {
    background-position: -100% 0;
  }
}

@mixin componentPreloader() {
  @extend .component-placeholder;

  @content;
}

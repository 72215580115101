@import "../../../bps/components/counterInput/counterInput.scss";

.counter {
  &__input {
    display: flex;
    justify-content: center;
    align-items: center;

    &:focus-visible { // stylelint-disable-line
      outline: 0 none;
      box-shadow: 0 0 0 2px $brandPrimaryColor;
    }
  }

  &:focus-visible, // stylelint-disable-line
  &:focus-within, // stylelint-disable-line
  &--active {
    .counter__button {
      height: 36px;

      &--increase {
        border-radius: $baseBorderRadiusSmall $baseBorderRadiusSmall 0 0;

        &::after {
          bottom: -25px;
          height: 25px;

          @media (min-width: $screen-desktop) {
            bottom: -22px;
            height: 22px;
          }
        }
      }

      &--decrease {
        border-radius: 0 0 $baseBorderRadiusSmall $baseBorderRadiusSmall;

        &::after {
          top: -25px;
          height: 25px;

          @media (min-width: $screen-desktop) {
            top: -22px;
            height: 22px;
          }
        }
      }
    }
  }
}
